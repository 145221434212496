<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="recipe === undefined">
      <h4 class="alert-heading">Error fetching invoice data</h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link class="alert-link" to="/">
          <!-- :to="{ name: 'apps-invoice-list'}" -->
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert>

    <b-row v-if="recipe" class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card border-0">
          <!-- Header -->
          <b-card-body v-if="!AppointmentId" class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->
              <div class="w-50">
                <div class="logo-wrapper">
                  <img
                    src="https://res.cloudinary.com/dv0edcyev/image/upload/v1678660984/logo-tchf.png_xlvyf5.webp"
                    width="538"
                    height="70"
                    alt="Tu Clínica Hispana Familiar"
                    data-height-percentage="80" />
                </div>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-2 d-flex">
                <div class="w-100">
                  <div class="card-text mb-1 d-flex text-break">
                    <h1 style="color: #fdc787">FACILITY</h1>
                  </div>
                  <div class="card-text mb-1 d-flex text-break">
                    <span class="boldPrint">
                      {{ facility.adress }}
                    </span>
                  </div>
                  <p class="card-text mb-1 d-flex text-break">
                    <span class="boldPrint"
                      >{{ facility.cities.name }}, {{ facility.cities.states.name }}</span
                    >
                  </p>
                  <p class="card-text mb-1 d-flex text-break">
                    <span class="boldPrint"> +1 {{ facility.phone }} </span>
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <img :src="getSeparator" alt="" srcset="" />

          <!-- Invoice Client & Payment Details -->
          <b-card-body v-if="patient" class="invoice-padding pt-0">
            <b-row class="invoice-spacing rowPatient">
              <!-- Col: Invoice To -->
              <b-col>
                <div class="invoicex">
                  <h6 class="boldPrint">{{ patient.name }} {{ patient.lastname }}</h6>
                  <h6 class="card-text boldPrint">
                    <span class="font-weight-bold">DOB</span>:
                    {{ patient.date_birth }}
                  </h6>
                  <h6 class="card-text boldPrint">
                    <span class="font-weight-bold">P</span>: {{ formatPhone }}
                  </h6>
                </div>
              </b-col>
              <b-col>
                <div class="invoiceText">
                  <h1 class="text-uppercase boldPrint">
                    {{ $t('Invoice') }} {{ recipe.id }}
                  </h1>
                  <p class="mb-25 boldPrint">
                    <span
                      >Invoice Date:
                      {{ new Date(recipe.created_at).toLocaleString() }}</span
                    >
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-container fluid>
            <b-table
              id="table-patient-items"
              responsive
              :items="recipeDescription"
              :fields="fields">
              <template #head(name)="data">
                <span class="headLabel"> {{ $t(data.label) }}</span>
              </template>
              <template #head(unite_price)="data">
                <span class="headLabel"> {{ $t(data.label) }}</span>
              </template>
              <template #head(quantity)="data">
                <span class="headLabel"> {{ $t(data.label) }}</span>
              </template>
              <template #head(price)="data">
                <span class="headLabel"> {{ $t(data.label) }}</span>
              </template>
            </b-table>
            <div style="border: 1px solid #49938e" />
          </b-container>
          <!-- <template #cell(price)="data">
            <span> {{ $t(data.label) }} </span>
          </template>
          <span> {{ getPrice(data.item.price) }} </span> -->

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-1"
                style="background-color: #e9efef; border-radius: 15px">
                <img :src="recipe.signature" class="img-fluid" alt="Signature" />

                <h6 class="boldPrint">{{ patient.name }} {{ patient.lastname }}</h6>
              </b-col>

              <!-- Col: Total -->
              <b-col cols="12" md="6" class="d-flex justify-content-end">
                <div class="invoice-total-wrapper w-100" style="margin-top: -5%">
                  <div
                    class="invoice-total-item"
                    v-if="recipe.appointments.campaign_cupon">
                    <p class="invoice-total-title boldPrint">Campaign</p>
                    <p class="invoice-total-amount boldPrint">
                      {{ recipe.appointments.campaign_cupon.campaign.name }} /
                      {{ recipe.appointments.campaign_cupon.campaign.value }}
                      {{
                        recipe.appointments.campaign_cupon.campaign.type == 'percentage'
                          ? '%'
                          : ''
                      }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title boldPrint">Subtotal</p>
                    <p class="invoice-total-amount boldPrint">
                      $
                      {{
                         subtotal
                      }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title boldPrint">{{ $t('Consultation + copay') }}</p>
                    <p class="invoice-total-amount boldPrint">
                      $ {{ consultPrice.price + recipe.appointments.copay }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title boldPrint">{{ $t('Discount') }}</p>
                    <p class="invoice-total-amount boldPrint">
                      {{ recipe.appointments.ajustment }}
                    </p>
                  </div>
                  <div
                    class="invoice-total-item cursor-pointer"
                    @click="openRefund"
                    v-for="(item, index) in recipe.appointments.refunds"
                    :key="index">
                    <p class="invoice-total-title boldPrint">Refund:</p>
                    <p class="invoice-total-amount text-danger boldPrint">
                      - $ {{ item.value }}
                    </p>
                  </div>
                  <div style="border: 1px solid transparent; padding-top: 5%" />
                  <div class="invoice-total-item">
                    <p class="text-uppercase boldPrint">Total</p>
                    <div style="background-color: #ff922c; color: white; padding: 3%">
                      <h3 class="boldPrint">$ {{ recipe.appointments.total }}</h3>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-col cols="12" md="12" lg="6">
                <b-card-text class="mb-0 pt-3">
                  <h5 class="font-weight-bold">Payment Terms:</h5>
                  <p>
                    Note: For questions concerning this invoice please contact (214)
                    888-9000 / manager@clinicahf.us
                  </p>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing-hr noPrint" />
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions noPrint">
        <b-card>
          <!-- Button: DOwnload -->
          <!-- <b-button
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Download
          </b-button> -->
          <router-link
            v-if="AppointmentId"
            :to="{ name: 'recipe-patient', params: { id: AppointmentId } }">
            <i class="far fa-eye cursor-pointer mx-1 noPrint"></i>
            See more</router-link
          >
          <!-- Button: Print -->
          <b-button
            v-else
            variant="outline-primary"
            class="mb-75 noPrint"
            block
            @click="printInvoice">
            Print
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <RefundView
      class="noPrint"
      :dataRefund="dataRefund"
      v-if="isViewRefund"
      @closeModal="(isViewRefund = null), (dataRefund = null)" />
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BContainer,
  BTable,
  BTfoot,
  BTh,
  BTr,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'
import Logo from '@/components/Logo.vue'
import RefundView from './RefundView.vue'

import axiosOV from '@/core/services/apiInsurance/office-visit'
import axiosAR from '@/core/services/apiInsurance/appointment/refund'
import layoutConfig from '@/core/config/layout.config.json'

export default {
  directives: {
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BContainer,
    BTable,
    BCardText,
    BButton,
    BAlert,
    BLink,

    RefundView,
  },
  data() {
    return {
      hasPackage: false,
      fields: [
        {
          key: 'name',
          label: 'Name',
          class: ['th-class-invoice-border-left', 'test'],
          thStyle: {
            color: 'white',
            backgroundColor: '#499a94',
          },
        },
        {
          key: 'options',
          label: 'Discount type',
          class: 'th-class-invoice-patient',
          thStyle: {
            color: 'white',
            backgroundColor: '#49938e',
          },
        },
        {
          key: 'realPrice',
          label: 'Real price',
          class: 'th-class-invoice-patient',
          thStyle: {
            color: 'white',
            backgroundColor: '#49938e',
          },
        },
        {
          key: 'quantity',
          label: 'Quantity',
          class: 'th-class-invoice-patient',
          thStyle: {
            color: 'white',
            backgroundColor: '#49948f',
          },
        },
        {
          key: 'price',
          label: 'Price',
          class: 'th-class-invoice-border-right',
          thStyle: {
            color: 'white',
            backgroundColor: '#49807b',
          },
        },
      ],
      recipe: null,
      patient: null,
      facility: null,
      recipeDescription: null,
      subtotal: 0,
      consultPrice: null,
      isViewRefund: false,
      dataRefund: null,
      logoFooter: localStorage.getItem('imageLogo'),
    }
  },
  props: {
    AppointmentId: {
      type: Number,
      required: false,
    },
  },
  mounted() {
    this.getRecipe()
  },
  computed: {
    getSeparator() {
      return process.env.BASE_URL + 'media/misc/separador.png'
    },
    dateIssued() {
      if (this.recipe.created_at) {
        return new Date(this.recipe.created_at).toLocaleString()
      } else {
        return ''
      }
    },
    formatPhone: {
      get() {
        let { phone } = this.patient
        phone = phone.toString().replace(/\D/g, '')
        const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/)
        if (match) {
          phone = `(${match[1]}${match[2] ? '' : ''}) ${match[2]}${
            match[3] ? '-' : ''
          }${match[3]}`
        }
        return phone
      },
      set(val) {
        this.patient.phone = val
      },
    },
  },
  methods: {
    getPrice(price) {
      if (this.hasPackage) return 'price in package'
      if (price) return price
      return 'not assigned'
    },
    getRecipe() {
      axiosOV
        .officeVisitDetailt(this.AppointmentId || this.$route.params.id)
        .then(({ office_visit, insuranceDiscount }) => {
          this.recipe = office_visit
          const { appointments } = office_visit
          this.patient = appointments.patients
          this.facility = appointments.facilities

          const { consult_prices } = appointments
          this.consultPrice = consult_prices
          const {
            laboratories,
            laboratories_pack,
            diagnostic_imagings,
            procedures,
            tests,
            treatments,
            package_id,
          } = this.recipe
          this.hasPackage = package_id != null ? true : false
          const recipeDescription = [].concat(
            laboratories,
            laboratories_pack,
            diagnostic_imagings,
            procedures,
            tests,
            treatments
          )
          const mergedArray = Object.values(insuranceDiscount)
          const mergedFlat = mergedArray.flat(Infinity)
          const result2 = this.flattenObject(mergedFlat)

          recipeDescription.forEach((item2, index) => {
            const match = result2.find(item1 => item1.name === item2.name)
            if (match) {
              recipeDescription[index] = {
                ...item2,
                ...match,
                price: this.calculatePrice(match),
                realPrice: item2.price,
                options: this.selectedOptionsMach(match),
              }
            } else {
              const match = result2.find(item1 => item1.parentKey === item2.name)
              if (match) {
                recipeDescription[index] = {
                  ...item2,
                  selectedOption: match.selectedOption,
                  coinsurancePercentage: match.coinsurancePercentage,
                  realPrice: item2.price,
                  price: this.calculatePrice({
                    selectedOption: match.selectedOption,
                    coinsurancePercentage: match.coinsurancePercent,
                    price: item2.price,
                  }),
                  options: this.selectedOptionsMach({
                    selectedOption: match.selectedOption,
                    coinsurancePercentage: match.coinsurancePercent,
                    price: item2.price,
                  }),
                }
              }
            }
          })
          const result = recipeDescription.map(item => {
            let quantity = 1
            if (item.cat_treatments_id) {
              quantity = item.pivot.cant
            }
            this.subtotal += item.price * quantity
            return { quantity, ...item }
          })
          this.recipeDescription = result
          console.log('🚀 ~ .then ~ this.recipeDescription:', this.recipeDescription)
        })
    },
    calculatePrice(lab) {
      if (lab.selectedOption === '0') {
        return 0
      } else if (lab.selectedOption === 'coinsurance' && lab.coinsurancePercentage > 0) {
        return (lab.price * lab.coinsurancePercentage) / 100
      }
      return lab.price
    },
    selectedOptionsMach(lab) {
      if (lab.selectedOption === '0') {
        return 'Insurance Benefit'
      } else if (lab.selectedOption === 'coinsurance') {
        return 'Coinsurance'
      }
      return 'Self Pay'
    },
    flattenObject(obj) {
      const result = []

      // Función recursiva para procesar el objeto
      function process(item, parentKey = '') {
        if (Array.isArray(item)) {
          // Si es un array, procesa cada elemento
          item.forEach(subItem => process(subItem, parentKey))
        } else if (item && typeof item === 'object') {
          // Si es un objeto, verifica si contiene datos útiles
          const keys = Object.keys(item)
          if (keys.some(key => typeof item[key] !== 'object')) {
            // Si el objeto tiene datos planos, añádelo
            result.push({ ...item, parentKey })
          }
          // Procesa propiedades anidadas
          keys.forEach(key => process(item[key], key))
        }
      }

      process(obj)
      return result
    },
    printInvoice() {
      window.print()
    },
    downloadInvoice() {
      console.log(window.print())
    },
    openRefund() {
      axiosAR
        .appointmentRefundView(this.AppointmentId || this.$route.params.id)
        .then(({ registro }) => {
          this.dataRefund = registro
          this.isViewRefund = true
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/pages/invoice/invoice-6.scss';

.invoice-spacing-hr {
  border: 0;
  height: 20px;
  background: url('https://newtodesign.com/wp-content/uploads/2018/09/Divider-css.png') 0
    0;
}

.separator-vertical {
  border: none;
  border-left: 2px solid hsla(200, 10%, 50%, 100);
  margin-top: 0;
  height: 15vh;
  width: 1px;
}

.separator-vertical-md {
  border: none;
  border-left: 2px solid hsla(200, 10%, 50%, 100);
  margin-top: 0;
  height: 7vh;
  width: 1px;
}
</style>

<style lang="scss">
.th-class-invoice-patient {
  width: 33%;
}

.th-class-invoice-border-left {
  width: 33%;
  border-radius: 15px 0 0 0;
}

.th-class-invoice-border-right {
  width: 33%;
  border-radius: 0 15px 0 0;
}

@media print {
  @page {
    size: 210mm 297mm;
    /*margin: 5mm 5mm 5mm 5mm;*/
    orphans: 0;
    widows: 0;
  }

  .noPrint {
    display: none;
  }

  .th-class-invoice-patient {
    font-weight: bold;
  }

  .th-class-invoice-border-left {
    font-weight: bold;
  }

  .th-class-invoice-border-right {
    font-weight: bold;
  }

  // Global Styles
  .headLabel {
    color: black;
  }

  .boldPrint {
    font-weight: bold;
  }

  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  span {
    font-weight: bold;
  }

  .subheader,
  #kt_footer,
  #kt_header_mobile,
  #kt_header {
    display: none;
  }

  #kt_footer > .d-flex {
    display: none;
  }

  .table th {
    background-color: #3699ff !important;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  .separator-vertical {
    height: 9vh !important;
  }

  .separator-vertical-md {
    height: 4vh !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}

.rowPatient {
  background-color: #e9efef;
  border-radius: 15px;
}

.invoicex {
  margin-left: 10%;
  margin-top: 10%;
}

.invoiceText {
  margin-left: 30%;
  margin-top: 10%;
}
</style>
